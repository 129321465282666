import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaUser, FaEnvelope, FaChild, FaPlus, FaMinus, FaInfoCircle, FaCalendarAlt, FaPhone, FaSpinner, FaTrash } from 'react-icons/fa';
import DatePicker, { registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import "react-datepicker/dist/react-datepicker.css";
import "./custom-datepicker.css";
import config from '../../config';

registerLocale('de', de);

const scrollToRef = (ref) => {
  if (ref && ref.current) {
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};

const API_BASE_URL = config.API_BASE_URL;
const KITA_API_KEY = config.KITA_API_KEY;
const DEV_MODE = config.DEV_MODE === 'true';

const callPartialSubmission = async (email) => {
  try {
    const apiEndpoint = DEV_MODE
      ? 'http://localhost:8000/partialSubmission'
      : 'https://api.app.kita.kids/partialSubmission';

    const response = await axios.post(apiEndpoint, null, {
      params: { email },
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': KITA_API_KEY
      }
    });

    if (response.status === 200) {
      console.log('Partial submission successful');
    } else {
      console.error('Partial submission failed');
    }
  } catch (error) {
    console.error('Error during partial submission:', error);
  }
};

// Debug function
const debug = (message) => {
  console.log(`[DEBUG] ${message}`);
};

const StepIndicator = ({ currentStep, onStepClick }) => {
  const { t } = useTranslation();
  const steps = [t('form.step1'), t('form.step2'), t('form.step3')];
  const isDevMode = process.env.REACT_APP_DEV_MODE === 'true';

  return (
    <div className="flex flex-wrap justify-between items-center mb-8">
      {steps.map((step, index) => (
        <div 
          key={step} 
          className={`flex flex-col items-center mb-4 ${isDevMode ? 'cursor-pointer' : ''}`}
          onClick={() => isDevMode && onStepClick(index + 1)}
        >
          <div className={`w-8 h-8 rounded-full flex items-center justify-center ${
            index + 1 === currentStep ? 'bg-[#9151D2] text-white' : 'bg-gray-200 text-gray-600'
          }`}>
            {index + 1}
          </div>
          <span className="mt-2 text-xs sm:text-sm text-center">{step}</span>
        </div>
      ))}
    </div>
  );
};

const Forms = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [currentStep, setCurrentStep] = useState(1);
  const stepIndicatorRef = useRef(null);
  
  useEffect(() => {
    scrollToRef(stepIndicatorRef);
  }, [currentStep]);

  const [isLoading, setIsLoading] = useState(false);
  const needDateRef = useRef(null);
  const [formData, setFormData] = useState({
    businessPartner: location.pathname === '/voiio' ? 'voiio' : '',
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
    secondParentFirstName: '',
    secondParentLastName: '',
    children: [{ firstName: '', lastName: '', birthDate: null }],
    street: '',
    houseNumber: '',
    postalCode: '',
    city: '',
    personalStory: '',
    childImages: [],
    acceptPrivacyPolicy: false,
    acceptTerms: false,
    acceptMarketingUse: false,
    isSingleParent: false,
    acceptMarketingUseStep3: false,
    needDate: null
  });

  const handleStepClick = (step) => {
    setCurrentStep(step);
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      setFormData(prevState => ({
        ...prevState,
        childImages: [...prevState.childImages, ...Array.from(files)]
      }));
    } else {
      const newFormData = {
        ...formData,
        [name]: value
      };
      setFormData(newFormData);
      localStorage.setItem('formData', JSON.stringify(newFormData));
    }
  };

  const handleDeleteImage = (index) => {
    setFormData(prevState => ({
      ...prevState,
      childImages: prevState.childImages.filter((_, i) => i !== index)
    }));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChildChange = (index, field, value) => {
    const newChildren = [...formData.children];
    newChildren[index][field] = value;
    setFormData(prevState => ({
      ...prevState,
      children: newChildren
    }));
  };

  const calculateAge = (birthDate) => {
    if (!birthDate) return '';
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      return age - 1;
    }
    return age;
  };



  const addChild = () => {
    if (formData.children.length < 3) {
      setFormData(prevState => ({
        ...prevState,
        children: [...prevState.children, { firstName: '', lastName: '', birthDate: null }]
      }));
    }
  };

  const removeChild = (index) => {
    if (formData.children.length > 1) {
      setFormData(prevState => ({
        ...prevState,
        children: prevState.children.filter((_, i) => i !== index)
      }));
    }
  };

  useEffect(() => {
    debug(`Current step changed to: ${currentStep}`);
  }, [currentStep]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    debug('Form submitted');
  
    if (!isStepValid()) {
      debug('Form is not valid');
      return;
    }

    setIsLoading(true);

    // Create FormData object
    const formDataToSend = new FormData();
  
    // Append all form data
    formDataToSend.append('userName', `${formData.firstName} ${formData.lastName}`);
    formDataToSend.append('partnerName', `${formData.secondParentFirstName} ${formData.secondParentLastName}`.trim());
    formDataToSend.append('numberChildren', formData.children.length.toString());
    formDataToSend.append('childrenName', formData.children.map(child => `${child.firstName} ${child.lastName}`.trim()).join(', '));
    formDataToSend.append('adressStreet', `${formData.street} ${formData.houseNumber}`.trim());
    formDataToSend.append('adressPLZ', formData.postalCode);
    formDataToSend.append('adressCity', formData.city);
    formDataToSend.append('phone', formData.mobileNumber);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('story', formData.personalStory);
    formDataToSend.append('paymentStatus', 2574);
    formDataToSend.append('Nuture', formData.acceptMarketingUseStep3 ? 'true' : 'false');
    if (formData.businessPartner) {
      formDataToSend.append('businessPartner', formData.businessPartner);
    }

    formData.children.forEach((child, index) => {
      formDataToSend.append(`child${index + 1}Name`, `${child.firstName} ${child.lastName}`.trim());
      if (child.birthDate) {
        formDataToSend.append(`child${index + 1}Birthday`, child.birthDate.toISOString().split('T')[0]);
      }
    });

    formDataToSend.append('childrenBirthday', formData.children.map(child => child.birthDate ? child.birthDate.toISOString().split('T')[0] : '').join(', '));
    formDataToSend.append('submittedAt', new Date().toISOString());
    formDataToSend.append('needDate', formData.needDate ? formData.needDate.toISOString().split('T')[0] : '');

    // Append images if they exist
    formData.childImages.forEach((image, index) => {
      formDataToSend.append('childImage', image);
    });

    // Log the FormData object (note: FormData contents can't be directly logged)
    for (let [key, value] of formDataToSend.entries()) {
      console.log(key, value);
    }

    try {
      const apiEndpoint = DEV_MODE
        ? 'http://localhost:8000/formSubmission'
        : 'https://api.app.kita.kids/formSubmission';


      const response = await axios.post(apiEndpoint, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-API-Key': KITA_API_KEY
        }
      });
      if (response.status === 200 && response.data && response.data.status === "success") {
        console.log('Form submitted successfully:', response.data);
        // Save submission_id, row_id, and UUID in a cookie
        const { submission_id, row_id, UUID, userId } = response.data;
        if (submission_id && row_id && UUID && userId) {
          const cookieData = JSON.stringify({ submission_id, row_id, UUID, userId });
          Cookies.set('formSubmissionData', cookieData, { expires: 30 }); // Cookie expires in 30 days
          console.log('submission_id, row_id, and UUID saved in cookie');
        }
        // Redirect to the /orders page
        navigate('/orders');
        // Scroll to top after navigation
        window.scrollTo(0, 0);
      } else {
        console.error('Form submission failed or incomplete response:', response.data);
        console.error('Fehler bei der Übermittlung des Formulars. Bitte versuchen Sie es erneut.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const childrenRefs = useRef([]);
  const streetRef = useRef(null);
  const houseNumberRef = useRef(null);
  const postalCodeRef = useRef(null);
  const cityRef = useRef(null);

  const isStepValid = () => {
    switch (currentStep) {
      case 1:
        return formData.firstName && formData.lastName && formData.email && formData.mobileNumber && formData.needDate && formData.children.every(child => child.firstName && child.lastName && child.birthDate) && formData.acceptPrivacyPolicy && formData.acceptTerms;
      case 2:
        return formData.street && formData.houseNumber && formData.postalCode && formData.city;
      case 3:
        return formData.acceptPrivacyPolicy && formData.acceptTerms;
      default:
        return false;
    }
  };

  const scrollToFirstInvalidField = () => {
    switch (currentStep) {
      case 1:
        if (!formData.firstName) {
          firstNameRef.current?.scrollIntoView({ behavior: 'smooth' });
          return;
        }
        if (!formData.lastName) {
          lastNameRef.current?.scrollIntoView({ behavior: 'smooth' });
          return;
        }
        if (!formData.email) {
          emailRef.current?.scrollIntoView({ behavior: 'smooth' });
          return;
        }
        for (let i = 0; i < formData.children.length; i++) {
          if (!formData.children[i].firstName || !formData.children[i].lastName || !formData.children[i].birthDate) {
            childrenRefs.current[i]?.scrollIntoView({ behavior: 'smooth' });
            return;
          }
        }
        break;
      case 2:
        if (!formData.street) {
          streetRef.current?.scrollIntoView({ behavior: 'smooth' });
          return;
        }
        if (!formData.houseNumber) {
          houseNumberRef.current?.scrollIntoView({ behavior: 'smooth' });
          return;
        }
        if (!formData.postalCode) {
          postalCodeRef.current?.scrollIntoView({ behavior: 'smooth' });
          return;
        }
        if (!formData.city) {
          cityRef.current?.scrollIntoView({ behavior: 'smooth' });
          return;
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-4 sm:p-8 mt-16">
      {location.pathname === '/voiio' ? (
        <div className="mx-auto mb-10 w-full max-w-[400px] md:max-w-[350px] lg:max-w-[300px] flex flex-col items-center justify-center">
          <div className="flex items-center justify-center mb-6">
            <img src={`${process.env.PUBLIC_URL}/KitaKidsLogo.svg`} alt="KitaKids Logo" className="w-2/5 h-auto" />
            <span className="mx-4 text-3xl sm:text-4xl font-bold text-gray-500">+</span>
            <img src={`${process.env.PUBLIC_URL}/voiioSchrift.webp`} alt="Voiio Logo" className="w-2/5 h-auto" />
          </div>
          <p className="text-sm text-gray-600 text-center mb-4">{t('form.partnershipText')}</p>
        </div>
      ) : (
        <div className="mx-auto mb-6 w-full max-w-[307px] md:max-w-[250px] lg:max-w-[200px] rounded-lg overflow-hidden shadow-md">
          <img src={`${process.env.PUBLIC_URL}/thumbUp.webp`} alt={t('form.thumbsUpAlt')} className="w-full h-auto" />
        </div>
      )}
      <h2 className="text-3xl font-bold text-[#9151D2] mb-6 text-center">{t('form.title')}</h2>
      <StepIndicator ref={stepIndicatorRef} currentStep={currentStep} onStepClick={handleStepClick} />
      <form onSubmit={handleSubmit} className="space-y-6">
        <>
          {currentStep === 1 && (
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
            <div className="relative">
              <label htmlFor="firstName" className="block mb-2 text-lg text-gray-700">{t('form.firstName')}</label>
              <div className="relative">
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  className={`w-full p-3 pl-10 text-lg border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#9151d2] ${!formData.firstName ? 'border-red-500' : 'border-gray-300'}`}
                  required
                  placeholder={t('form.firstNamePlaceholder')}
                  ref={firstNameRef}
                />
                <FaUser className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
              </div>
            </div>
            <div className="relative">
              <label htmlFor="lastName" className="block mb-2 text-lg text-gray-700">{t('form.lastName')}</label>
              <div className="relative">
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  className={`w-full p-3 pl-10 text-lg border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#9151d2] ${!formData.lastName ? 'border-red-500' : 'border-gray-300'}`}
                  required
                  placeholder={t('form.lastNamePlaceholder')}
                  ref={lastNameRef}
                />
                <FaUser className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
              </div>
            </div>
          </div>
          )}
          {currentStep === 1 && (
          <div className="relative">
            <label htmlFor="email" className="block mb-2 text-lg text-gray-700">{t('form.email')}</label>
            <div className="relative">
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                onBlur={() => {
                  if (formData.email && !validateEmail(formData.email)) {
                    setFormData(prevState => ({
                      ...prevState,
                      emailError: t('form.invalidEmail')
                    }));
                  } else {
                    setFormData(prevState => ({
                      ...prevState,
                      emailError: ''
                    }));
                  }
                }}
                className={`w-full p-3 pl-10 text-lg border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#9151d2] ${formData.emailError ? 'border-red-500' : 'border-gray-300'}`}
                required
                placeholder={t('form.emailPlaceholder')}
                ref={emailRef}
              />
              <FaEnvelope className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
            </div>
            {formData.emailError && (
              <p className="mt-2 text-sm text-red-600">{formData.emailError}</p>
            )}
          </div>
          )}
          {currentStep === 1 && (
          <div className="relative">
            <label htmlFor="mobileNumber" className="block mb-2 text-lg text-gray-700">{t('form.mobileNumber')}</label>
            <div className="relative">
              <input
                type="tel"
                id="mobileNumber"
                name="mobileNumber"
                value={formData.mobileNumber}
                onChange={handleChange}
                className={`w-full p-3 pl-10 text-lg border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#9151d2] ${!formData.mobileNumber ? 'border-red-500' : 'border-gray-300'}`}
                required
                placeholder={t('form.mobileNumberPlaceholder')}
              />
              <FaPhone className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
            </div>
          </div>
          )}
          {currentStep === 1 && (
          <div className="relative">
            <label htmlFor="needDate" className="block mb-2 text-lg text-gray-700">{t('form.startDate')}</label>
            <div className="relative">
              <div ref={needDateRef}>
                <DatePicker
                  id="needDate"
                  selected={formData.needDate}
                  onChange={(date) => setFormData({...formData, needDate: date})}
                  dateFormat="dd.MM.yyyy"
                  className={`w-full p-3 pl-10 text-lg border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#9151d2] ${!formData.needDate ? 'border-red-500' : 'border-gray-300'}`}
                  required
                  placeholderText={t('form.startDatePlaceholder')}
                  locale="de"
                />
              </div>
              <FaCalendarAlt className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
            </div>
          </div>
          )}
          {currentStep === 1 && (
          <div className="mt-6">
            <div className="mb-4">
              <label className="flex items-center text-gray-700 text-lg cursor-pointer">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-[#9151d2]"
                  checked={formData.isSingleParent}
                  onChange={(e) => setFormData({...formData, isSingleParent: e.target.checked})}
                />
                <span className="ml-2">{t('form.singleParent')}</span>
              </label>
            </div>
            {!formData.isSingleParent && (
              <>
                <h3 className="text-xl font-bold mb-4">{t('form.secondParent')}</h3>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
                  <div className="relative">
                    <label htmlFor="secondParentFirstName" className="block mb-2 text-lg text-gray-700">{t('form.firstName')}</label>
                    <div className="relative">
                      <input
                        type="text"
                        id="secondParentFirstName"
                        name="secondParentFirstName"
                        value={formData.secondParentFirstName}
                        onChange={handleChange}
                        className="w-full p-3 pl-10 text-lg border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#9151d2]"
                        placeholder={t('form.firstNamePlaceholder')}
                      />
                      <FaUser className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
                    </div>
                  </div>
                  <div className="relative">
                    <label htmlFor="secondParentLastName" className="block mb-2 text-lg text-gray-700">{t('form.lastName')}</label>
                    <div className="relative">
                      <input
                        type="text"
                        id="secondParentLastName"
                        name="secondParentLastName"
                        value={formData.secondParentLastName}
                        onChange={handleChange}
                        className="w-full p-3 pl-10 text-lg border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#9151d2]"
                        placeholder={t('form.lastNamePlaceholder')}
                      />
                      <FaUser className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          )}
          {currentStep === 1 && (
          <div>
            <h3 className="text-xl font-bold mb-4">{t('form.children')}</h3>
            {formData.children.map((child, index) => (
              <div key={index} className="mb-6" ref={el => childrenRefs.current[index] = el}>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                  <div>
                    <label htmlFor={`childFirstName${index}`} className="block mb-2 text-sm font-medium text-gray-700">{t('form.childFirstName')}</label>
                    <div className="relative">
                      <input
                        type="text"
                        id={`childFirstName${index}`}
                        placeholder={t('form.childFirstNamePlaceholder')}
                        value={child.firstName}
                        onChange={(e) => handleChildChange(index, 'firstName', e.target.value)}
                        className={`w-full p-4 pl-11 text-lg border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#9151d2] ${!child.firstName ? 'border-red-500' : 'border-gray-300'}`}
                        required
                      />
                      <FaChild className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 text-xl" />
                    </div>
                  </div>
                  <div>
                    <label htmlFor={`childLastName${index}`} className="block mb-2 text-sm font-medium text-gray-700">{t('form.childLastName')}</label>
                    <div className="relative">
                      <input
                        type="text"
                        id={`childLastName${index}`}
                        placeholder={t('form.childLastNamePlaceholder')}
                        value={child.lastName}
                        onChange={(e) => handleChildChange(index, 'lastName', e.target.value)}
                        className={`w-full p-4 pl-11 text-lg border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#9151d2] ${!child.lastName ? 'border-red-500' : 'border-gray-300'}`}
                        required
                      />
                      <FaUser className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 text-xl" />
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <label htmlFor={`childBirthDate${index}`} className="block mb-2 text-sm font-medium text-gray-700">{t('form.childBirthDate')}</label>
                  <div className="relative">
                    <DatePicker
                      id={`childBirthDate${index}`}
                      selected={child.birthDate}
                      onChange={(date) => handleChildChange(index, 'birthDate', date)}
                      dateFormat="dd.MM.yyyy"
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={100}
                      placeholderText={t('form.childBirthDatePlaceholder')}
                      className={`w-full p-4 pl-11 text-lg border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#9151d2] ${!child.birthDate ? 'border-red-500' : 'border-gray-300'}`}
                      required
                      locale="de"
                    />
                    <FaCalendarAlt className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 text-xl" />
                  </div>
                </div>
                {child.birthDate && (
                  <p className="text-sm text-gray-600 mb-4">
                    {t('form.childAge', { age: calculateAge(child.birthDate) })}
                  </p>
                )}
                {formData.children.length > 1 && (
                  <button
                    type="button"
                    onClick={() => removeChild(index)}
                    className="w-full p-4 bg-[#FA5877] text-white rounded-lg hover:bg-[#E84D6C] transition duration-300 flex items-center justify-center"
                  >
                    <FaMinus className="mr-2" /> {t('form.removeChild')}
                  </button>
                )}
              </div>
            ))}
            <div className="flex justify-start mt-4">
              <button
                type="button"
                onClick={addChild}
                disabled={formData.children.length >= 3}
                className={`w-1/2 p-2 bg-white rounded-lg transition duration-300 flex items-center justify-center border ${
                  formData.children.length >= 3
                    ? 'border-gray-400 text-gray-400 cursor-not-allowed'
                    : 'border-[#24D0BD] text-[#24D0BD] hover:bg-[#24D0BD] hover:text-white'
                }`}
              >
                <FaPlus className="mr-2" /> {t('form.addChild')}
              </button>
            </div>
          </div>
          )}
          {currentStep === 1 && (
            <div className="flex flex-col space-y-4 mt-6">
              <div className="mb-4">
                <label className="flex items-center text-gray-700 text-sm cursor-pointer">
                  <div className="relative">
                    <input
                      type="checkbox"
                      className="sr-only"
                      checked={formData.acceptMarketingUse}
                      onChange={(e) => setFormData({...formData, acceptMarketingUse: e.target.checked})}
                    />
                    <div className={`w-8 h-8 border-2 rounded-md ${formData.acceptMarketingUse ? 'bg-[#25d0be] border-[#25d0be]' : 'border-gray-300'} transition-colors duration-200 ease-in-out`}>
                      {formData.acceptMarketingUse && (
                        <svg className="w-6 h-6 text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                        </svg>
                      )}
                    </div>
                  </div>
                  <span className="ml-3">
                    {t('form.marketingConsent')}
                  </span>
                </label>
              </div>
              <div className="mb-4">
                <label className="flex items-center text-gray-700 text-sm cursor-pointer">
                  <div className="relative">
                    <input
                      type="checkbox"
                      className="sr-only"
                      checked={formData.acceptPrivacyPolicy}
                      onChange={(e) => setFormData({...formData, acceptPrivacyPolicy: e.target.checked})}
                      required
                    />
                    <div className={`w-8 h-8 border-2 rounded-md ${formData.acceptPrivacyPolicy ? 'bg-[#25d0be] border-[#25d0be]' : 'border-gray-300'} transition-colors duration-200 ease-in-out`}>
                      {formData.acceptPrivacyPolicy && (
                        <svg className="w-6 h-6 text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                        </svg>
                      )}
                    </div>
                  </div>
                  <span className="ml-3">
                    {t('form.privacyPolicyConfirmation')} <a href="https://www.kita.kids/privacy" className="text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">Datenschutzerklärung</a>
                  </span>
                </label>
              </div>
              <div className="mb-4">
                <label className="flex items-center text-gray-700 text-sm cursor-pointer">
                  <div className="relative">
                    <input
                      type="checkbox"
                      className="sr-only"
                      checked={formData.acceptTerms}
                      onChange={(e) => setFormData({...formData, acceptTerms: e.target.checked})}
                      required
                    />
                    <div className={`w-8 h-8 border-2 rounded-md ${formData.acceptTerms ? 'bg-[#25d0be] border-[#25d0be]' : 'border-gray-300'} transition-colors duration-200 ease-in-out`}>
                      {formData.acceptTerms && (
                        <svg className="w-6 h-6 text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                        </svg>
                      )}
                    </div>
                  </div>
                  <span className="ml-3">
                    {t('form.termsAcceptance')} <a href="https://www.kita.kids/imprint-agb" className="text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">Allgemeinen Geschäftsbedingungen (AGB)</a>
                  </span>
                </label>
              </div>
            </div>
          )}
          {currentStep === 2 && (
            <div>
              <h3 className="text-xl font-bold mb-4">{t('form.address')}</h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
                <div>
                  <label htmlFor="street" className="block mb-2 text-lg text-gray-700">{t('form.street')}</label>
                  <input
                    type="text"
                    id="street"
                    name="street"
                    value={formData.street}
                    onChange={handleChange}
                    className={`w-full p-3 text-lg border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#9151d2] ${!formData.street ? 'border-red-500' : 'border-gray-300'}`}
                    required
                    placeholder={t('form.streetPlaceholder')}
                    ref={streetRef}
                  />
                </div>
                <div>
                  <label htmlFor="houseNumber" className="block mb-2 text-lg text-gray-700">{t('form.houseNumber')}</label>
                  <input
                    type="text"
                    id="houseNumber"
                    name="houseNumber"
                    value={formData.houseNumber}
                    onChange={handleChange}
                    className={`w-full p-3 text-lg border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#9151d2] ${!formData.houseNumber ? 'border-red-500' : 'border-gray-300'}`}
                    required
                    placeholder={t('form.houseNumberPlaceholder')}
                    ref={houseNumberRef}
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6 mt-4 sm:mt-6">
                <div>
                  <label htmlFor="postalCode" className="block mb-2 text-lg text-gray-700">{t('form.postalCode')}</label>
                  <input
                    type="text"
                    id="postalCode"
                    name="postalCode"
                    value={formData.postalCode}
                    onChange={handleChange}
                    className={`w-full p-3 text-lg border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#9151d2] ${!formData.postalCode ? 'border-red-500' : 'border-gray-300'}`}
                    required
                    placeholder={t('form.postalCodePlaceholder')}
                    ref={postalCodeRef}
                  />
                </div>
                <div>
                  <label htmlFor="city" className="block mb-2 text-lg text-gray-700">{t('form.city')}</label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    className={`w-full p-3 text-lg border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#9151d2] ${!formData.city ? 'border-red-500' : 'border-gray-300'}`}
                    required
                    placeholder={t('form.cityPlaceholder')}
                    ref={cityRef}
                  />
                </div>
              </div>
            </div>
          )}
          {currentStep === 3 && (
            <div>
              <h3 className="text-2xl font-bold mb-4 text-[#9151D2]">{t('form.step3Title')}</h3>
              <div className="flex items-center mb-4">
                <div className="bg-[#9151D2] rounded-full p-2 mr-4">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                  </svg>
                </div>
                <p className="text-gray-700 font-semibold">
                  {t('form.step3Subtitle')}
                </p>
              </div>
              <ul className="list-disc list-inside text-gray-600 mb-4 space-y-2">
                {t('form.step3Benefits', { returnObjects: true }).map((benefit, index) => (
                  <li key={index}>{benefit}</li>
                ))}
              </ul>
              <p className="text-sm text-gray-500 mb-2">
                {t('form.step3Note')}
              </p>
              <p className="text-sm text-gray-500 mb-4">
                {t('form.step3Description')}
              </p>
                <div className="mb-6">
                  <label htmlFor="personalStory" className="block mb-2 text-lg font-semibold text-gray-700">{t('form.personalStory')}</label>
                  <textarea
                    id="personalStory"
                    name="personalStory"
                    value={formData.personalStory}
                    onChange={handleChange}
                    className="w-full p-3 text-lg border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#9151d2]"
                    rows="6"
                    placeholder={t('form.personalStoryPlaceholder')}
                  ></textarea>
                </div>
                <div className="mb-6">
                  <label htmlFor="childImage" className="block mb-2 text-lg font-semibold text-gray-700">{t('form.uploadImagesTitle')}</label>
                  <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg">
                    <div className="space-y-1 text-center">
                      <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                        <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      <div className="flex justify-center">
                        <label htmlFor="childImage" className="relative cursor-pointer bg-[#9151d2] text-white py-2 px-4 rounded-md font-medium hover:bg-[#7b3ab5] focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-[#9151d2] transition duration-300">
                          <span className="text-lg">{t('form.uploadImagesButton')}</span>
                          <input
                            id="childImage"
                            name="childImage"
                            type="file"
                            multiple
                            className="sr-only"
                            onChange={handleChange}
                            accept="image/*"
                          />
                        </label>
                      </div>
                      <p className="text-xs text-gray-500">{t('form.uploadImagesDescription')}</p>
                    </div>
                  </div>
                  {formData.childImages.length > 0 && (
                    <div className="mt-4">
                      <p className="font-semibold mb-2">{t('form.selectedImages')}</p>
                      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
                        {formData.childImages.map((image, index) => (
                          <div key={index} className="relative">
                            <img 
                              src={URL.createObjectURL(image)} 
                              alt={`Vorschau ${index + 1}`} 
                              className="w-full h-32 object-cover rounded-lg"
                            />
                            <button
                              type="button"
                              onClick={() => handleDeleteImage(index)}
                              className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1 m-1 hover:bg-red-600 transition duration-300"
                            >
                              <FaTrash size={14} />
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
          )}
          {currentStep === 3 && (
            <div className="flex flex-col space-y-4 mt-6">
              <div className="mb-4">
                <label className="flex items-center text-gray-700 text-sm cursor-pointer">
                  <div className="relative">
                    <input
                      type="checkbox"
                      className="sr-only"
                      checked={formData.acceptMarketingUseStep3}
                      onChange={(e) => setFormData({...formData, acceptMarketingUseStep3: e.target.checked})}
                    />
                    <div className={`w-8 h-8 border-2 rounded-md ${formData.acceptMarketingUseStep3 ? 'bg-blue-600 border-blue-600' : 'border-gray-300'} transition-colors duration-200 ease-in-out`}>
                      {formData.acceptMarketingUseStep3 && (
                        <svg className="w-6 h-6 text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                        </svg>
                      )}
                    </div>
                  </div>
                  <span className="ml-3">
                    {t('form.marketingConsentStep3')}
                  </span>
                </label>
              </div>
              <div className="mb-4">
                <label className="flex items-center text-gray-700 text-sm cursor-pointer">
                  <div className="relative">
                    <input
                      type="checkbox"
                      className="sr-only"
                      checked={formData.acceptPrivacyPolicyStep3}
                      onChange={(e) => setFormData({...formData, acceptPrivacyPolicyStep3: e.target.checked})}
                      required
                    />
                    <div className={`w-8 h-8 border-2 rounded-md ${formData.acceptPrivacyPolicyStep3 ? 'bg-blue-600 border-blue-600' : 'border-gray-300'} transition-colors duration-200 ease-in-out`}>
                      {formData.acceptPrivacyPolicyStep3 && (
                        <svg className="w-6 h-6 text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                        </svg>
                      )}
                    </div>
                  </div>
                  <span className="ml-3">
                    {t('form.privacyPolicyConfirmation')} <a href="https://www.kita.kids/privacy" className="text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">Datenschutzerklärung</a>
                  </span>
                </label>
              </div>
              <div className="mb-4">
                <label className="flex items-center text-gray-700 text-sm cursor-pointer">
                  <div className="relative">
                    <input
                      type="checkbox"
                      className="sr-only"
                      checked={formData.acceptTermsStep3}
                      onChange={(e) => setFormData({...formData, acceptTermsStep3: e.target.checked})}
                      required
                    />
                    <div className={`w-8 h-8 border-2 rounded-md ${formData.acceptTermsStep3 ? 'bg-blue-600 border-blue-600' : 'border-gray-300'} transition-colors duration-200 ease-in-out`}>
                      {formData.acceptTermsStep3 && (
                        <svg className="w-6 h-6 text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                        </svg>
                      )}
                    </div>
                  </div>
                  <span className="ml-3">
                    {t('form.termsAcceptance')} <a href="https://www.kita.kids/imprint-agb" className="text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">Allgemeinen Geschäftsbedingungen (AGB)</a>
                  </span>
                </label>
              </div>
            </div>
          )}
          <div className="flex flex-col space-y-4">
            <div className="flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0">
              {currentStep > 1 && (
                <button 
                  type="button"
                  onClick={() => setCurrentStep(currentStep - 1)}
                  className="px-6 py-3 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition duration-300 text-lg font-semibold"
                >
                  {t('form.back')}
                </button>
              )}
              <button 
                type="button"
                onClick={async (e) => {
                  debug(`Button clicked, current step: ${currentStep}`);
                  if (currentStep < 3) {
                    if (currentStep === 1) {
                      if (!validateEmail(formData.email)) {
                        setFormData(prevState => ({
                          ...prevState,
                          emailError: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.'
                        }));
                        if (emailRef.current) {
                          const yOffset = -100; // Adjust this value as needed
                          const y = emailRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
                          window.scrollTo({ top: y, behavior: 'smooth' });
                          emailRef.current.focus();
                        }
                        return;
                      }
                      if (!formData.needDate) {
                        if (needDateRef.current) {
                          const yOffset = -100; // Adjust this value as needed
                          const y = needDateRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
                          window.scrollTo({ top: y, behavior: 'smooth' });
                        }
                        return;
                      }
                    }
                    if (isStepValid() && (currentStep !== 1 || (formData.acceptPrivacyPolicy && formData.acceptTerms))) {
                      if (currentStep === 1 && formData.acceptMarketingUse) {
                        await callPartialSubmission(formData.email);
                      }
                      setCurrentStep(currentStep + 1);
                    } else {
                      scrollToFirstInvalidField();
                    }
                  } else if (currentStep === 3) {
                    if (isStepValid() && formData.acceptPrivacyPolicyStep3 && formData.acceptTermsStep3) {
                      handleSubmit(e);
                    } else {
                      scrollToFirstInvalidField();
                    }
                  }
                }}
                className={`px-6 py-3 bg-[#9151d2] hover:bg-[#7b3ab5] text-white rounded-lg transition duration-300 text-lg font-semibold ${currentStep > 1 ? 'sm:ml-4' : 'w-full'} ${((currentStep === 1 && !(formData.acceptPrivacyPolicy && formData.acceptTerms)) || (currentStep === 3 && !(formData.acceptPrivacyPolicyStep3 && formData.acceptTermsStep3))) || isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={((currentStep === 1 && !(formData.acceptPrivacyPolicy && formData.acceptTerms)) || (currentStep === 3 && !(formData.acceptPrivacyPolicyStep3 && formData.acceptTermsStep3))) || isLoading}
              >
                {isLoading ? (
                  <FaSpinner className="animate-spin inline-block mr-2" />
                ) : null}
                {currentStep === 3 ? (isLoading ? t('form.sending') : t('form.submit')) : t('form.next')}
              </button>
            </div>
          </div>
        </>
      </form>
    </div>
  );
};

export default Forms;

import { Client, Account, Databases } from 'appwrite';

const client = new Client();

client
    .setEndpoint('https://appwrite.app.kita.kids/v1') // Your Appwrite Endpoint
    .setProject('669ec338000c02295a59'); // Your project ID

export const account = new Account(client);
export const databases = new Databases(client);

export { client };

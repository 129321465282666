import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SimpleHeader from './components/SimpleHeader';
import Forms from './components/form/Forms';
import Find from './components/find/Find';
import Orders from './components/Orders';
import { AuthProvider } from './contexts/AuthContext';

const AppContent = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <SimpleHeader />

      {/* Hauptinhalt */}
      <main className="flex-grow px-4 sm:px-6 lg:px-8 py-8 max-w-7xl mx-auto w-full">
        <Routes>
          <Route path="/form" element={<Forms />} />
          <Route path="/find" element={<Find />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/voiio" element={<Forms />} />
          <Route path="*" element={<Navigate to="/form" replace />} />
        </Routes>
      </main>

      {/* Fußzeile */}
      <footer className="bg-gray-100 py-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center text-gray-600">
          <p className="text-lg mb-4">{t('footer.copyright', { year: new Date().getFullYear() })}</p>
          <div className="flex justify-center space-x-4">
            <a href="https://www.kita.kids/imprint-agb" className="text-blue-600 hover:text-blue-800">{t('footer.imprint')}</a>
            <a href="https://www.kita.kids/privacy" className="text-blue-600 hover:text-blue-800">{t('footer.privacy')}</a>
          </div>
        </div>
      </footer>
    </div>
  );
};

const KitaKidsApp = () => {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
};

export default KitaKidsApp;

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

const SimpleHeader = () => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const savedLang = Cookies.get('language');
    if (savedLang) {
      i18n.changeLanguage(savedLang);
    } else {
      const browserLang = navigator.language || navigator.userLanguage;
      const lang = browserLang.toLowerCase().includes('de') ? 'de' : 'en';
      i18n.changeLanguage(lang);
      Cookies.set('language', lang, { expires: 365 });
    }
  }, [i18n]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    Cookies.set('language', lng, { expires: 365 });
    setIsOpen(false);
  };

  const getCurrentLanguageFlag = () => {
    return `/locales/${i18n.language}/flag.svg`;
  };

  return (
    <header className="bg-white shadow-md fixed top-0 left-0 right-0 z-50">
      <div className="container mx-auto px-4 py-3">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <img src="/KitaKidsLogo.svg" alt="KitaKids Logo" className="h-8 w-auto" />
          </div>
          <div className="relative">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="flex items-center px-3 py-2 border rounded text-gray-700 border-gray-300 hover:text-gray-900 hover:border-gray-400"
            >
              <img src={getCurrentLanguageFlag()} alt={t(`language.${i18n.language}`)} className="w-6 h-4 mr-2" />
              <span>{t(`language.${i18n.language}`)}</span>
              <svg className="ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>
            {isOpen && (
              <div className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20">
                <button
                  className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                  onClick={() => changeLanguage('de')}
                >
                  <img src="/locales/de/flag.svg" alt={t('language.german')} className="w-6 h-4 mr-2" />
                  {t('language.german')}
                </button>
                <button
                  className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                  onClick={() => changeLanguage('en')}
                >
                  <img src="/locales/en/flag.svg" alt={t('language.english')} className="w-6 h-4 mr-2" />
                  {t('language.english')}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default SimpleHeader;

import React, { createContext, useState, useContext, useEffect } from 'react';
import { account } from '../utils/appwrite';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkSession = async () => {
      if (isVerifying) {
        console.log('Skipping session check during verification');
        return;
      }

      setIsLoading(true);
      try {
        const session = await account.getSession('current');
        if (session) {
          const userData = await account.get();
          setUser(userData);
          setIsLoggedIn(true);
        } else {
          throw new Error('No session found');
        }
      } catch (error) {
        console.log('No active session');
        setIsLoggedIn(false);
        setUser(null);
      } finally {
        setIsLoading(false);
      }
    };
    
    // Add a timeout to allow the session to build up
    const timeoutId = setTimeout(() => {
      checkSession();
    }, 1000); // 1 second delay

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  }, [isVerifying]);

  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn, isVerifying, setIsVerifying, user, setUser, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

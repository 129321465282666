import React, { useState, useEffect } from 'react';
import { X, Loader } from 'lucide-react';
import config from '../config';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const DEV_MODE = config.DEV_MODE === 'true';

const updateUserLocation = async (userId, UUID, facilities) => {
  const apiEndpoint = DEV_MODE
    ? 'http://localhost:8000/user_location'
    : 'https://api.app.kita.kids/user_location';

  try {
    const response = await axios.post(`${apiEndpoint}/${userId}/${UUID}`, {
      facilityIDs: facilities.map(facility => facility.replace(/"/g, ''))
    }, {
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': config.KITA_API_KEY
      }
    });
    if (response.status === 200) {
      console.log('Facilities updated successfully');
    }
  } catch (error) {
    console.error('Error updating facilities:', error);
  }
};

const Modal = ({ isOpen, onClose, onConfirm, message, disableContinue, onAdjustSelection, onContinueWithTrim, continueWithTrimText }) => {
  const { t } = useTranslation();
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">{t('packages.confirmationRequired')}</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>
        <p className="mb-4">{message}</p>
        <p className="mb-6">{t('packages.pleaseCheckTerms')} <a href="https://www.kita.kids/imprint-agb" className="text-[#9151D2] hover:underline">{t('packages.termsAndConditions')}</a>.</p>
        <div className="flex justify-end space-x-4">
          {disableContinue ? (
            <>
              <button
                onClick={onAdjustSelection}
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition duration-300"
              >
                {t('packages.adjustSelection')}
              </button>
              <button
                onClick={onContinueWithTrim}
                className="px-4 py-2 bg-[#9151D2] text-white rounded hover:bg-[#7b3ab5] transition duration-300"
              >
                {continueWithTrimText}
              </button>
            </>
          ) : (
            <>
              <button
                onClick={onClose}
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition duration-300"
              >
                {t('packages.cancel')}
              </button>
              <button
                onClick={onConfirm}
                className="px-4 py-2 bg-[#9151D2] text-white rounded hover:bg-[#7b3ab5] transition duration-300"
              >
                {t('packages.continue')}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const PackageCard = ({ title, description, price, originalPrice, features, highlight, packageId, locationCount, selectedFacilitiesCount, paymentStatus }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  
  const selectedLocations = Cookies.get('selectedLocations') || '';
  const facilitiesCount = selectedLocations.trim() !== '' ? selectedLocations.split(',').length : 0;
  
  const handleClick = async (e) => {
    e.preventDefault();
    const formSubmissionData = JSON.parse(Cookies.get('formSubmissionData') || '{}');
    if (formSubmissionData && formSubmissionData.UUID) {
      if (facilitiesCount !== locationCount) {
        setIsModalOpen(true);
      } else {
        setIsLoading(true);
        await redirectToShop(formSubmissionData.UUID);
      }
    } else {
      console.error('UUID not found in formSubmissionData cookie');
      // Handle the case when UUID is not available
    }
  };

  const redirectToShop = async (UUID) => {
    const formSubmissionData = JSON.parse(Cookies.get('formSubmissionData') || '{}');
    const selectedLocations = Cookies.get('selectedLocations') || '';
    const facilities = selectedLocations.trim() !== '' ? selectedLocations.split(',') : [];

    if (formSubmissionData.UUID && formSubmissionData.userId) {
      await updateUserLocation(formSubmissionData.userId, formSubmissionData.UUID, facilities);
    } else {
      console.error('UUID or userId not found in formSubmissionData cookie');
    }

    const apiEndpoint = DEV_MODE
      ? 'http://localhost:8000/redirectToShop'
      : `${config.API_BASE_URL}/redirectToShop`;
    window.location.href = `${apiEndpoint}?UUID=${UUID}&package=${packageId}`;
  };

  const handleConfirm = async () => {
    setIsModalOpen(false);
    setIsLoading(true);
    const formSubmissionData = JSON.parse(Cookies.get('formSubmissionData') || '{}');
    await redirectToShop(formSubmissionData.UUID);
  };

  const handleAdjustSelection = () => {
    setIsModalOpen(false);
    const findElement = document.querySelector('[data-testid="find-kitas-todo"]');
    if (findElement) {
      findElement.scrollIntoView({ behavior: 'smooth' });
      // Check if the Find component is not already visible
      const findComponent = document.querySelector('[data-testid="find-component"]');
      if (!findComponent || findComponent.style.display === 'none') {
        findElement.click();
      }
    }
    
    // Ensure the Find component is visible and scroll to it
    setTimeout(() => {
      const findComponent = document.querySelector('[data-testid="find-component"]');
      if (findComponent) {
        findComponent.style.display = 'block';
        findComponent.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
    
    const formSubmissionData = JSON.parse(Cookies.get('formSubmissionData') || '{}');
    formSubmissionData.kitasSelected = false;
    Cookies.set('formSubmissionData', JSON.stringify(formSubmissionData));
  };

  const handleContinueWithTrim = async () => {
    setIsModalOpen(false);
    setIsLoading(true);
    const selectedLocationsArray = selectedLocations.split(',');
    const trimmedLocations = selectedLocationsArray.slice(0, locationCount);
    const trimmedLocationsString = trimmedLocations.join(',');
    Cookies.set('selectedLocations', trimmedLocationsString);
    const formSubmissionData = JSON.parse(Cookies.get('formSubmissionData') || '{}');
    
    if (formSubmissionData.UUID && formSubmissionData.userId) {
      await updateUserLocation(formSubmissionData.userId, formSubmissionData.UUID, trimmedLocations);
    } else {
      console.error('UUID or userId not found in formSubmissionData cookie');
    }

    redirectToShop(formSubmissionData.UUID);
  };

  const getModalMessage = () => {
    if (facilitiesCount > locationCount) {
      return t('packages.tooManyLocations', { selected: facilitiesCount, available: locationCount }) + ' ' + t('packages.continueWithTrimMessage', { count: locationCount });
    } else if (facilitiesCount < locationCount) {
      return t('packages.fewerLocations', { selected: facilitiesCount, available: locationCount });
    }
    return t('packages.confirmSelection');
  };

  return (
    <>
      <div className={`bg-gray-100 p-6 rounded-lg shadow-lg flex flex-col h-full transition-all duration-300 ${highlight ? 'border-2 border-[#9151D2] transform hover:scale-105' : 'hover:shadow-xl'}`}>
        {highlight && <span className="bg-[#9151D2] text-white text-sm font-semibold px-3 py-1 rounded-full self-start mb-2">{highlight}</span>}
        <h3 className="text-2xl font-bold mb-2 text-[#020617]">{title}</h3>
        <p className="text-gray-700 mb-4">{description}</p>
        <div className="mb-4">
          {originalPrice ? (
            <div className="flex flex-col">
              <div className="flex items-center">
                <p className="text-4xl font-bold text-[#9151D2]">€{price}</p>
                <div className="ml-2 flex flex-col items-start">
                  <p className="text-lg line-through text-gray-500">€{originalPrice}</p>
                  <p className="text-sm font-semibold text-green-600">{t('packages.voiioAdvantage')}</p>
                </div>
              </div>
              <span className="text-sm font-normal text-gray-700 mt-1">{t('packages.oneTimeIncVAT')}</span>
            </div>
          ) : (
            <div className="flex flex-col">
              <p className="text-4xl font-bold text-[#9151D2]">€{price}</p>
              <span className="text-sm font-normal text-gray-700 mt-1">{t('packages.oneTimeIncVAT')}</span>
            </div>
          )}
        </div>
        <ul className="mb-6 flex-grow space-y-2">
          {features.map((feature, index) => (
            <li key={index} className="flex items-start">
              <svg className="w-5 h-5 mr-2 text-[#24D0BD] flex-shrink-0 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
              </svg>
              <span className="text-gray-700">{feature}</span>
            </li>
          ))}
        </ul>
        {paymentStatus?.value !== 'paid' && (
          <button 
            onClick={handleClick}
            className="bg-[#9151D2] text-white font-semibold py-3 px-6 rounded-lg hover:bg-[#7b3ab5] transition duration-300 text-lg inline-block text-center w-full"
            disabled={isLoading}
          >
            {isLoading ? (
              <div className="flex items-center justify-center">
                <Loader className="animate-spin h-5 w-5 mr-3" />
                {t('packages.loading')}
              </div>
            ) : (
              t('packages.completeAndSecure')
            )}
          </button>
        )}
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirm}
        onAdjustSelection={handleAdjustSelection}
        onContinueWithTrim={handleContinueWithTrim}
        message={getModalMessage()}
        disableContinue={facilitiesCount !== locationCount}
        continueWithTrimText={facilitiesCount < locationCount ? t('packages.continue') : t('packages.continueWithTrim', { count: locationCount })}
      />
    </>
  );
};

const Packages = ({ selectedFacilitiesCount = '', paymentStatus = null }) => {
  const { t } = useTranslation();
  const [isVoiioPartner, setIsVoiioPartner] = useState(false);

  useEffect(() => {
    const formData = JSON.parse(localStorage.getItem('formData') || '{}');
    const formSubmissionData = JSON.parse(Cookies.get('formSubmissionData') || '{}');
    
    if (formData.businessPartner === 'voiio' || formSubmissionData.businessPartner === 'voiio') {
      setIsVoiioPartner(true);
    }
  }, []);

  const packages = isVoiioPartner
    ? [
        {
          title: "voiio",
          description: t('packages.highSpeed.description'),
          price: "198",
          originalPrice: "298",
          features: [
            //t('packages.highSpeed.feature1'),
            t('packages.perfectImpression.feature3'),
            t('packages.highSpeed.feature2'),
            t('packages.highSpeed.feature3'),
            t('packages.highSpeed.feature4'),
           
          ],
          highlight: "voiio Partner",
          packageId: "48760397431126", // Replace with actual package ID
          locationCount: 15
        }
      ]
    : [
        {
          title: t('packages.perfectImpression.title'),
          description: t('packages.perfectImpression.description'),
          price: "198",
          features: [
            t('packages.perfectImpression.feature1'),
            t('packages.perfectImpression.feature2'),
            t('packages.perfectImpression.feature3')
          ],
          packageId: "47998968201558",
          locationCount: 15
        },
        {
          title: t('packages.highSpeed.title'),
          description: t('packages.highSpeed.description'),
          price: "298",
          features: [
            t('packages.highSpeed.feature1'),
            t('packages.highSpeed.feature2'),
            t('packages.highSpeed.feature3'),
            t('packages.highSpeed.feature4')
          ],
          highlight: t('packages.mostPopular'),
          packageId: "47998926848342",
          locationCount: 15
        },
        {
          title: t('packages.giveMoreTitle'),
          description: t('packages.giveMoreDescription'),
          price: "598",
          features: [
            t('packages.giveMore.feature1'),
            t('packages.giveMore.feature2'),
            t('packages.giveMore.feature3'),
            t('packages.giveMore.feature4'),
            <span className="font-bold">{t('packages.giveMore.feature5')}</span>
          ],
          highlight: t('packages.extremeReach'),
          packageId: "47998846730582",
          locationCount: 20 
        }
      ];

  return (
    <div className="packages py-20 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        {packages.length > 1 && (
          <h3 className="text-3xl font-bold text-center mb-12 text-[#020617]">{t('packages.ourPackages')}</h3>
        )}
        <div className={`grid ${packages.length === 1 ? 'grid-cols-1 max-w-md mx-auto' : 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3'} gap-8`}>
          {packages.filter(Boolean).map((pkg, index) => (
            <PackageCard key={index} {...pkg} selectedFacilitiesCount={selectedFacilitiesCount} paymentStatus={paymentStatus} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Packages;
